import { Collapse } from "@mui/material";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  InputHTMLAttributes,
} from "react";

export type AutopaySelectionProps = {
  autopay: boolean;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const AutopaySelection: FC<AutopaySelectionProps> = ({
  autopay,
  onBlur,
  onChange,
}) => {
  const { t } = useTranslation("enrollment/formFields");
  return (
    <>
      <Box maxWidth="50%" mt={6} mb={1}>
        <Typography variant="h4" data-cy="autopay-agreement-prompt">
          {t("payment_autopayPrompt")}
        </Typography>
      </Box>
      <FormControl>
        <RadioGroup
          aria-labelledby="autopay-selection-group"
          name="autopaySelectionGroup"
          value={autopay}
          row
        >
          <FormControlLabel
            control={
              <Radio
                checked={autopay}
                onBlur={onBlur}
                onChange={onChange}
                value="true"
                inputProps={
                  {
                    "data-cy": "autopay-yes-radiobutton",
                  } as InputHTMLAttributes<HTMLInputElement>
                }
                data-cy="autopay-yes-checked"
              />
            }
            label={
              <Typography variant="body1" lineHeight="2.5">
                {t("payment_autopayYes")}
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Radio
                checked={!autopay}
                onBlur={onBlur}
                onChange={onChange}
                value="false"
                inputProps={
                  {
                    "data-cy": "autopay-no-radiobutton",
                  } as InputHTMLAttributes<HTMLInputElement>
                }
                data-cy="autopay-no-checked"
              />
            }
            label={
              <Typography variant="body1" lineHeight="2.5">
                {t("payment_autopayNo")}
              </Typography>
            }
          />
        </RadioGroup>
      </FormControl>
      <Collapse in={autopay}>
        <Box mb={1}>
          <FormControl>
            <FormControlLabel
              name="autopay-agreement-yes"
              sx={{
                ".MuiFormControlLabel-asterisk": {
                  display: "none",
                },
              }}
              control={
                <Checkbox
                  required={autopay}
                  inputProps={
                    {
                      "data-cy": "autopay-agreement-checkbox",
                    } as InputHTMLAttributes<HTMLInputElement>
                  }
                />
              }
              label={
                <Typography
                  component="span"
                  variant="body1"
                  lineHeight={1.5}
                  data-cy="autopay-agreement-label"
                >
                  {t("payment_autopayAgreement")}
                </Typography>
              }
            />
          </FormControl>
        </Box>
      </Collapse>
    </>
  );
};
