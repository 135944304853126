import { AccountQuery } from "@core/apiRequests/account/__generated__/useQueryAccount.generated";
import { GetPropertiesQuery } from "@core/apiRequests/account/__generated__/useQueryProperties.generated";
import { PaymentType } from "@core/apiRequests/graphql-global-types";
import { DefaultPaymentInstructionQuery } from "@core/apiRequests/payment/__generated__/useQueryDefaultPaymentInstruction.generated";
import { OctopusProductId } from "@core/product";
import { Maybe } from "@core/types";
import {
  addMinutes,
  addYears,
  format,
  formatDistanceToNowStrict,
  isAfter,
  isBefore,
  parseISO,
} from "date-fns";

/**
 * Formats number in cents as dollar & cents
 * @example
 * // returns 100.00
 * formatAmount(10000, 2);
 */
export const formatAmount = (
  amount: number | undefined | null,
  decimalPlaces = 2
) => {
  if (amount === 0) {
    return "0.00";
  }
  if (!amount) {
    return;
  }

  return (amount * 0.01).toFixed(decimalPlaces);
};

/**
 * Formats date
 * @example
 * // returns May 09 2022
 * formatDate("2022-05-09", "MMM dd yyyy");
 */
export const formatDate = (date: string, dateFormat: string) => {
  if (!date) {
    return;
  }
  const dateFormatted = new Date(date);
  return format(
    addMinutes(dateFormatted, dateFormatted.getTimezoneOffset()),
    dateFormat
  );
};

/**
 * Calculates the time frame (in days) between an ISO formated date
 * and today's date
 * @example - (if today was 9/9/2022)
 * // returns 251
 * formatDate("2023-05-19T05:00:00+00:00");
 */

export const formatDistanceToNow = (startDate: string) => {
  if (!startDate) {
    return;
  }

  const totalDaysString = formatDistanceToNowStrict(new Date(startDate), {
    unit: "day",
  });
  // NOTE: date-fns returns "251 days" so we have to split the output if
  // we want just "251" to be returned.
  const str = totalDaysString.split(" ");
  const totalDays = Number(str[0]);
  return totalDays;
};

export const addYearsToDate = (
  startDate: string | number | Date,
  yearsToAdd: number
) => {
  if (!startDate) {
    return;
  }

  const dateFormatted = format(parseISO(startDate as string), "yyyy, M, d");
  return addYears(new Date(dateFormatted), yearsToAdd);
};

export enum Campaign {
  FAN_CLUB = "fan_club",
  SOLAR_PANELS = "solar-panels",
  WITH_EV = "octo12_ev",
  WITH_THERMOSTAT = "octo12_thermostat",
  SUNNY_MONEY = "sunny_money",
}

export type AccountCampaign = {
  __typename?: "AccountCampaignType" | undefined;
  campaignExpiryDate?: string;
  name?: string | null | undefined;
  slug?: string | null | undefined;
  expiryDate?: string;
  startDate?: string;
};

export const isCampaignUnexpired = (
  accountCampaign: AccountCampaign
): boolean => {
  const now = new Date();

  return (
    accountCampaign.campaignExpiryDate === null ||
    Boolean(
      accountCampaign.campaignExpiryDate &&
        isAfter(parseISO(accountCampaign.campaignExpiryDate), now)
    )
  ); //ORing against null because we don't want to return false if it's null
};

export const accountHasUnexpiredCampaign = (
  accountCampaign: AccountCampaign
): boolean => {
  const now = new Date();

  return (
    accountCampaign.expiryDate === null ||
    Boolean(
      accountCampaign.expiryDate &&
        isAfter(parseISO(accountCampaign.expiryDate), now)
    )
  ); //ORing against null because we don't want to return false if it's null
};

export const accountHasStartedCampaign = (
  accountCampaign: AccountCampaign
): boolean => {
  const now = new Date();

  return (
    accountCampaign.startDate === null ||
    Boolean(
      accountCampaign.startDate &&
        isBefore(parseISO(accountCampaign.startDate), now)
    )
  ); //ORing against null because we don't want to return false if it's null
};

const isCampaignValid = (accountCampaign: AccountCampaign): boolean => {
  return Boolean(
    isCampaignUnexpired(accountCampaign) &&
      accountHasUnexpiredCampaign(accountCampaign) &&
      accountHasStartedCampaign(accountCampaign)
  );
};

export const accountHasCampaignFactory =
  (campaign: Campaign) =>
  (accountData: Maybe<AccountQuery>): boolean => {
    const accountCampaign = accountData?.account?.campaigns?.find(
      (c) => c?.slug === campaign
    );

    if (accountCampaign) {
      return isCampaignValid(accountCampaign);
    } else {
      return false;
    }
  };

export const accountHasFanClubCampaign = accountHasCampaignFactory(
  Campaign.FAN_CLUB
);

export const accountHasEV = accountHasCampaignFactory(Campaign.WITH_EV);

export const accountHasThermostat = accountHasCampaignFactory(
  Campaign.WITH_THERMOSTAT
);

export const accountHasSolarContestCampaign = accountHasCampaignFactory(
  Campaign.SUNNY_MONEY
);

export const getAgreement = (data?: GetPropertiesQuery) => {
  return { ...data?.properties?.[0]?.meterPoints?.[0]?.agreements?.[0] };
};

export const getPlan = (data?: GetPropertiesQuery) => {
  const { code, prepay, ...product } = getAgreement(data)?.product || {};
  return {
    code: code as OctopusProductId | undefined,
    isPrepay: prepay,
    ...product,
  };
};

export const getPaymentScheduleType = (data?: AccountQuery) => {
  return data?.account?.paymentSchedules?.edges[0]?.node?.scheduleType;
};

export const getBalanceDisplay = (accountBalance: Maybe<number>) => {
  const formattedAccountBalance = formatAmount(Math.abs(accountBalance || 0));
  const balanceSign = accountBalance && accountBalance > 0 ? "-" : "";
  return `${balanceSign}$${formattedAccountBalance}`;
};

export const getInstructionType = (data?: DefaultPaymentInstructionQuery) => {
  return data?.defaultPaymentInstruction?.instructionType as Maybe<PaymentType>;
};

export const getMeterPoint = (data?: AccountQuery) => {
  return data?.account?.properties?.[0]?.meterPoints?.[0];
};
