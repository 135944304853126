import { ProductListType } from "@core/krakenHelpers";
import { Maybe } from "@core/types";
import { useState } from "react";
import { CreditCheckStatus } from ".";
import { OctopusProductId } from "../product";
import { useOctopusProducts } from "../product/useOctopusProducts";
import { BankAccountDetailsType } from "./finalize/payment/BankAccountPaymentMethod";
import {
  CreditCheckType,
  PersonalDetails,
  ServiceAddress,
  SwitchDetails,
} from "./getReady";

export type FormData = {
  getReady?: ServiceAddress & SwitchDetails & PersonalDetails;
};

type EnrollmentContextValuesProps = {
  formData: FormData;
  selectedPlanID: OctopusProductId | null;
  octopusProducts: ProductListType;
  creditCheckType: CreditCheckType;
  creditCheckStatus: CreditCheckStatus;
  krakenAccountID: string | null;
  financialConnectionsDetails: BankAccountDetailsType | undefined;
  ledgerId: string | undefined | null;
  paymentMethodId: string;
  prepayAutoTopUpAmount: number;
  monthlyKwhUsage: number;
  withEV: boolean;
  withThermostat: boolean;
  quoteCode: string;
  showCalculatingQuote: boolean;
  quoteFromEmail: boolean;
  zipCodeHasMultipleTdspsOrLoadZones: boolean;
  customerHasEnteredAddressEarly: boolean;
};

export const useEnrollmentContextValues = () => {
  const [formData, setFormData] = useState<FormData>({});
  const [selectedPlanID, setSelectedPlanID] = useState<OctopusProductId | null>(
    null
  );
  const [creditCheckStatus, setCreditCheckStatus] = useState<CreditCheckStatus>(
    CreditCheckStatus.PASS
  );
  const [creditCheckType, setCreditCheckType] = useState<CreditCheckType>(
    CreditCheckType.RUN_CREDIT_CHECK
  );
  const [krakenAccountID, setKrakenAccountID] = useState<string | null>(null);
  const [ledgerId, setLedgerId] = useState<string | undefined | null>(null);
  // unique payment method id generated from Stripe
  const [paymentMethodId, setPaymentMethodId] = useState<string>("");
  const [prepayAutoTopUpAmount, setPrepayAutoTopUpAmount] =
    useState<number>(7500);
  const [monthlyKwhUsage, setMonthlyKwhUsage] = useState<number | string>(1000);
  const [withEV, setWithEV] = useState<Maybe<boolean>>(false);
  const [withThermostat, setWithThermostat] = useState<Maybe<boolean>>(false);
  const [quoteCode, setQuoteCode] = useState<string | undefined>(undefined);
  const [quoteFromEmail, setQuoteFromEmail] = useState<Maybe<boolean>>(false);
  const [
    zipCodeHasMultipleTdspsOrLoadZones,
    setZipCodeHasMultipleTdspsOrLoadZones,
  ] = useState<boolean>(false);
  const [customerHasEnteredAddressEarly, setCustomerHasEnteredAddressEarly] =
    useState<boolean>(false);
  const [financialConnectionsDetails, setFinancialConnectionsDetails] =
    useState<BankAccountDetailsType | undefined>(undefined);

  const [{ octopusProducts }, setOctopusProducts] = useOctopusProducts();

  const setEnrollmentContextValues = (values: EnrollmentContextValuesProps) => {
    setFormData(values.formData);
    setSelectedPlanID(values.selectedPlanID);
    setOctopusProducts({
      octopusProducts: values.octopusProducts || [],
    });
    setCreditCheckStatus(values.creditCheckStatus);
    setCreditCheckType(values.creditCheckType);
    setCustomerHasEnteredAddressEarly(values.customerHasEnteredAddressEarly);
    setKrakenAccountID(values.krakenAccountID);
    setLedgerId(values.ledgerId);
    setPaymentMethodId(values.paymentMethodId);
    setPrepayAutoTopUpAmount(values.prepayAutoTopUpAmount);
    setMonthlyKwhUsage(values.monthlyKwhUsage);
    setWithEV(values.withEV);
    setWithThermostat(values.withThermostat);
    setQuoteCode(values.quoteCode);
    setQuoteFromEmail(values.quoteFromEmail);
    setZipCodeHasMultipleTdspsOrLoadZones(
      values.zipCodeHasMultipleTdspsOrLoadZones
    );
    setFinancialConnectionsDetails(values.financialConnectionsDetails);
  };
  return {
    formData,
    selectedPlanID,
    octopusProducts,
    creditCheckStatus,
    creditCheckType,
    krakenAccountID,
    ledgerId,
    paymentMethodId,
    prepayAutoTopUpAmount,
    monthlyKwhUsage,
    withEV,
    withThermostat,
    quoteCode,
    quoteFromEmail,
    zipCodeHasMultipleTdspsOrLoadZones,
    customerHasEnteredAddressEarly,
    financialConnectionsDetails,
    setEnrollmentContextValues,
    setFormData,
    setSelectedPlanID,
    setCreditCheckStatus,
    setCreditCheckType,
    setKrakenAccountID,
    setLedgerId,
    setPaymentMethodId,
    setPrepayAutoTopUpAmount,
    setMonthlyKwhUsage,
    setWithEV,
    setWithThermostat,
    setQuoteCode,
    setQuoteFromEmail,
    setZipCodeHasMultipleTdspsOrLoadZones,
    setCustomerHasEnteredAddressEarly,
    setFinancialConnectionsDetails,
  };
};
