import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC, InputHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";
import { Locale } from "../../constants";

import {
  PREPAID_DISCLOSURE_STATEMENT_URL_ENGLISH,
  PREPAID_DISCLOSURE_STATEMENT_URL_SPANISH,
  TERMS_OF_SERVICE_URL_ENGLISH,
  TERMS_OF_SERVICE_URL_SPANISH,
  YOUR_RIGHTS_AS_A_CUSTOMER_URL_ENGLISH,
  YOUR_RIGHTS_AS_A_CUSTOMER_URL_SPANISH,
} from "@core/legal";
import { useRouter } from "next/router";
import { useSelectedEnrollmentProduct } from "../enrollmentUtils";

type EnrollmentFinalAgreementProps = {
  currentProductIsPrepay?: boolean;
  fraudPreventionCheckboxProps?: CheckboxProps;
  specificProductConsentCheckboxProps?: CheckboxProps;
  serviceTermsCheckboxProps?: CheckboxProps;
};

export type EnrollmentAgreementsFormPartial = {
  productConsent: boolean;
  acceptEflYracTos: boolean;
  acceptFraudPrevention: boolean;
};

export const EnrollmentFinalAgreements: FC<EnrollmentFinalAgreementProps> = ({
  currentProductIsPrepay,
  fraudPreventionCheckboxProps,
  serviceTermsCheckboxProps,
}) => {
  const { t } = useTranslation("enrollment/formFields");
  const locale = useRouter().locale;
  const selectedProduct = useSelectedEnrollmentProduct();
  const { register, watch } = useFormContext<EnrollmentAgreementsFormPartial>();

  return (
    <>
      <Typography
        variant="h6"
        fontWeight="bold"
        mb={1}
        data-cy="final-agreements-section-title"
      >
        {t("finalise_consentHead")}
      </Typography>

      <FormGroup sx={{ mt: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              inputProps={
                {
                  "data-cy": "service-terms-checkbox",
                } as InputHTMLAttributes<HTMLInputElement>
              }
              data-cy="service-terms"
              {...register("acceptEflYracTos")}
              checked={watch().acceptEflYracTos}
              {...serviceTermsCheckboxProps}
            />
          }
          label={
            <Typography variant="body2" data-cy="service-terms-label">
              {t("finaliseAcceptEflYracTos1")}{" "}
              <a
                href={
                  selectedProduct?.serviceProviders[0]?.[
                    locale === Locale.ENGLISH ? "efl_en" : "efl_es"
                  ]?.url || "/404"
                }
                target="blank"
              >
                EFL
              </a>
              {", "}
              <a
                href={
                  locale === Locale.ENGLISH
                    ? YOUR_RIGHTS_AS_A_CUSTOMER_URL_ENGLISH
                    : YOUR_RIGHTS_AS_A_CUSTOMER_URL_SPANISH
                }
                target="_blank"
              >
                YRAC
              </a>
              {", "}
              {currentProductIsPrepay && (
                <>
                  <a
                    href={
                      locale === Locale.ENGLISH
                        ? PREPAID_DISCLOSURE_STATEMENT_URL_ENGLISH
                        : PREPAID_DISCLOSURE_STATEMENT_URL_SPANISH
                    }
                    target="_blank"
                  >
                    PDS
                  </a>
                  {", "}
                </>
              )}
              {t("finaliseAcceptEflYracTos2")}{" "}
              <a
                href={
                  locale === Locale.ENGLISH
                    ? TERMS_OF_SERVICE_URL_ENGLISH
                    : TERMS_OF_SERVICE_URL_SPANISH
                }
                target="_blank"
              >
                {t("finaliseAcceptEflYracTos3")}
              </a>
            </Typography>
          }
        />
      </FormGroup>
      <FormGroup sx={{ mt: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              inputProps={
                {
                  "data-cy": "fraud-prevention-checkbox",
                } as InputHTMLAttributes<HTMLInputElement>
              }
              data-cy="fraud-prevention"
              {...register("acceptFraudPrevention")}
              checked={watch().acceptFraudPrevention}
              {...fraudPreventionCheckboxProps}
            />
          }
          label={
            <Typography variant="body2" data-cy="fraud-prevention-label">
              {t("finalise_fraudPrevention")}
            </Typography>
          }
        />
      </FormGroup>
    </>
  );
};
