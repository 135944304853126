import {
  QUERY_ACCOUNT,
  QUERY_DEFAULT_PAYMENT_INSTRUCTION,
  QUERY_IS_AUTO_PAY_ENABLED,
  useChangePaymentScheduleTypeMutation,
  useStorePaymentInstructionMutation,
} from "@core/apiRequests";
import {
  PaymentScheduleTypeChoices,
  PaymentType,
} from "@core/apiRequests/graphql-global-types";
import { useBillingBreakdown } from "@core/dashboard/useBillingBreakdown";
import { handleApolloMutationError, handleError } from "@core/error";
import { useSnackbarNotification } from "@core/molecules";
import { Maybe } from "@core/types";
import { Button, Skeleton, Typography } from "@krakentech/coral";
import { Theme, useMediaQuery } from "@mui/material";
import { Box, Card, CardContent, Stack } from "@octopus-energy/coral-mui";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeCardNumberElement } from "@stripe/stripe-js";
import { formatISO } from "date-fns";
import useTranslation from "next-translate/useTranslation";
import { ChangeEvent, FC, FormEventHandler, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useBooleanState } from "react-use-object-state";
import {
  useGetEmbeddedSecretForNewPaymentInstructionMutation,
  useQueryDefaultPaymentInstruction,
} from "../apiRequests/payment";
import { DeletePaymentMethodSettings } from "./DeletePaymentMethodSettings";
import { CreditCardDetail } from "@core/stripe";

export enum AutopayPreferenceChoices {
  YES = "yes",
  NO = "no",
}

const VALID_FROM = formatISO(new Date());

export type PaymentCreditCardSettingsProps = {
  accountNumber: string;
  editing: ReturnType<typeof useBooleanState>;
  addCreditCardTrigger: ReturnType<typeof useBooleanState>;
  addBankAccountTrigger: ReturnType<typeof useBooleanState>;
  deleteCreditCardConfirmation: ReturnType<typeof useBooleanState>;
  preEnrolledInAutopay: ReturnType<typeof useBooleanState>;
};

export const PaymentCreditCardSettings: FC<PaymentCreditCardSettingsProps> = ({
  accountNumber,
  editing,
  addCreditCardTrigger,
  addBankAccountTrigger,
  deleteCreditCardConfirmation,
  preEnrolledInAutopay,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { t } = useTranslation("payments/payment-methods");
  const stripe = useStripe();
  const elements = useElements();
  const processing = useBooleanState(false);
  const [, setErrorMessage] = useState<Maybe<string>>(null);
  const [notification] = useSnackbarNotification();
  const { isPrepay } = useBillingBreakdown();

  const [autopayPreference, setAutopayPreference] =
    useState<AutopayPreferenceChoices | null>(null);

  const showAutopayErrorMessage = useBooleanState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAutopayPreference(event.target.value as AutopayPreferenceChoices);
    showAutopayErrorMessage.setFalse();
  };

  const form = useForm({
    mode: "onTouched",
  });

  const [getEmbeddedSecretForNewPaymentInstruction] =
    useGetEmbeddedSecretForNewPaymentInstructionMutation();

  const {
    loading: queryLoading,
    data,
    refetch,
  } = useQueryDefaultPaymentInstruction({
    variables: {
      accountNumber,
      instructionType: PaymentType.Card,
    },
    onError: handleError,
  });

  const [storePaymentInstruction, { loading: mutationLoading }] =
    useStorePaymentInstructionMutation({
      onError: handleApolloMutationError,
      refetchQueries: [
        {
          query: QUERY_DEFAULT_PAYMENT_INSTRUCTION,
          variables: {
            accountNumber,
            instructionType: PaymentType.Card,
          },
        },
        {
          query: QUERY_IS_AUTO_PAY_ENABLED,
          variables: {
            accountNumber,
          },
        },
        {
          query: QUERY_DEFAULT_PAYMENT_INSTRUCTION,
          variables: {
            accountNumber,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        notification.success(t("creditSuccessVerification"));
      },
    });

  const loading = queryLoading || mutationLoading;

  const [changeScheduleType] = useChangePaymentScheduleTypeMutation();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    processing.setTrue();
    setErrorMessage(null);

    if (!stripe || !elements) {
      return;
    }

    if (
      autopayPreference === null &&
      !isPrepay &&
      !preEnrolledInAutopay.state
    ) {
      showAutopayErrorMessage.setTrue();
      processing.setFalse();
      return;
    }

    try {
      const secretKey = await getEmbeddedSecretForNewPaymentInstruction({
        variables: {
          input: {
            accountNumber: accountNumber,
            instructionType: PaymentType.Card,
          },
        },
      })
        .then((res) => {
          return res.data?.getEmbeddedSecretForNewPaymentInstruction?.secretKey;
        })
        .catch(handleError);

      if (secretKey) {
        const cardSetupResult = await stripe.confirmCardSetup(secretKey, {
          payment_method: {
            card: elements.getElement(
              CardNumberElement
            ) as StripeCardNumberElement,
          },
        });

        if (cardSetupResult.error) {
          setErrorMessage(cardSetupResult.error.message);
          processing.setFalse();
          return;
        }

        const { data: storePaymentInstructionData } =
          await storePaymentInstruction({
            variables: {
              input: {
                accountNumber,
                validFrom: VALID_FROM,
                vendorReference: cardSetupResult.setupIntent
                  .payment_method as string,
                instructionType: PaymentType.Card,
              },
            },
          });

        if (storePaymentInstructionData) {
          notification.success(t("creditSuccessVerification"));
          addCreditCardTrigger.setFalse();
          editing.setFalse();

          if (
            autopayPreference === AutopayPreferenceChoices.YES ||
            preEnrolledInAutopay.state
          ) {
            const { data: changeScheduleTypeData } = await changeScheduleType({
              variables: {
                input: {
                  accountNumber,
                  scheduleType: PaymentScheduleTypeChoices.CardPayment,
                },
              },
              refetchQueries: [
                {
                  query: QUERY_ACCOUNT,
                  variables: {
                    accountNumber,
                  },
                },
              ],
            });
            if (changeScheduleTypeData) {
              setTimeout(() => {
                notification.success(t("autopayPreferenceSuccessPrompt"));
              }, 3000);
            }
          }
        }
      }
    } catch (error) {
      handleError(error);
    } finally {
      processing.setFalse();
    }
  };

  return loading ? (
    <Skeleton variant="rectangular" height={60} width="98%" />
  ) : addCreditCardTrigger.state ? (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <Box px={2} mt={2}>
          <Typography variant="body1">{t("creditCardInformation")}</Typography>
          <CreditCardDetail
            handleChange={handleChange}
            showAutopay
            showAutopayErrorMessage={showAutopayErrorMessage}
            isPrepay={Boolean(isPrepay)}
            preEnrolledInAutopay={preEnrolledInAutopay.state}
          />
          <Stack alignItems="flex-start" direction="row" mt={1} spacing={2}>
            <Button
              fullWidth
              variant="outlined"
              onClick={addCreditCardTrigger.setFalse}
            >
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              fullWidth
              type="submit"
              disabled={processing.state}
            >
              {t("savePaymentMethod")}
            </Button>
          </Stack>
        </Box>
      </form>
    </FormProvider>
  ) : (
    <Card color="background" borderWidth="none" sx={{ width: "100%", mt: 1 }}>
      <CardContent>
        <Stack direction="column" data-cy="credit-card-information-card">
          <Stack
            direction={deleteCreditCardConfirmation.state ? "column" : "row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Typography variant="caption" color="tertiary">
                {t("creditCard")}
              </Typography>
              <Typography variant={isMobile ? "body2" : "body1"}>
                {data?.defaultPaymentInstruction?.maskedAccountIdentifier ||
                  (!editing.state ? (
                    t("noCardPortalSettings")
                  ) : (
                    <Button
                      variant="text"
                      onClick={addCreditCardTrigger.setTrue}
                    >
                      <Typography>
                        <u>+ {t("addCreditCard")}</u>
                      </Typography>
                    </Button>
                  ))}
              </Typography>
            </Stack>
            {data?.defaultPaymentInstruction?.maskedAccountIdentifier &&
            editing.state ? (
              <DeletePaymentMethodSettings
                loading={loading}
                additionalPaymentType={PaymentType.DirectDebit}
                paymentMethodId={data?.defaultPaymentInstruction?.id || ""}
                refetch={refetch}
                editing={editing}
                addCreditCardTrigger={addCreditCardTrigger}
                addBankAccountTrigger={addBankAccountTrigger}
                preEnrolledInAutopay={preEnrolledInAutopay}
                setAutopayPreference={setAutopayPreference}
              />
            ) : (
              <Stack alignItems={isMobile ? "flex-start" : "flex-end"}></Stack>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
