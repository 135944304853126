import { useQueryAvailableServiceDates } from "@core/apiRequests";
import { ShellAccountEnrollmentTypeEnum } from "@core/apiRequests/graphql-global-types";
import { Alert } from "@krakentech/coral";
import { IconInfo } from "@krakentech/icons";
import { Checkbox, FormGroup, FormLabel } from "@mui/material";
import {
  Box,
  Collapse,
  Container,
  DesktopDatePicker,
  FormControl,
  FormControlLabel,
  Grid,
  LoadingButton,
  Radio,
  RadioGroup,
  TextField,
  TextFieldProps,
  Typography,
} from "@octopus-energy/coral-mui";
import { addDays, format } from "date-fns";
import useTranslation from "next-translate/useTranslation";
import { FC, InputHTMLAttributes, useEffect } from "react";
import { SubmitHandler } from "react-hook-form";
import { useBooleanState } from "react-use-object-state";
import {
  GetReadyFormData,
  useEnrollment,
  useGetReadyFormContext,
  useGetReadyFormController,
  useSelectedEnrollmentProductIsPrepay,
} from "..";
import { BlockedDate, getSwitchDateStatus } from "./blackoutDays";
import { CreditCheckOrDeposit } from "./CreditCheckOrDeposit";
import { DateOfBirth } from "./DateOfBirth";
import { Email } from "./Email";
import { PhoneNumber } from "./PhoneNumber";
import {
  SearchByAddress,
  SearchByEsiId,
  ServiceAddressPartial,
} from "./ServiceAddress";

export const PersonalDetails = () => {
  const { t } = useTranslation("enrollment/formFields");
  const {
    register,
    formState: { errors },
  } = useGetReadyFormContext();

  return (
    <Grid container spacing={3} data-cy="personal-information-section">
      <Grid item xs={12}>
        <TextField
          {...register("firstName")}
          label={t("labels_firstName")}
          data-cy="first-name"
          required
          fullWidth
          error={Boolean(errors.firstName)}
          helperText={errors.firstName?.message || " "}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register("lastName")}
          name="lastName"
          label={t("labels_lastName")}
          data-cy="last-name"
          required
          fullWidth
          error={Boolean(errors.lastName)}
          helperText={errors.lastName?.message || " "}
        />
      </Grid>
      <Grid item xs={12}>
        <Email />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PhoneNumber />
      </Grid>
      <Grid item xs={12} sm={6} data-cy="date-picker-grid">
        <DateOfBirth />
      </Grid>
    </Grid>
  );
};

const ServiceSwitchType = () => {
  const { t } = useTranslation("enrollment/formFields");
  const { field } = useGetReadyFormController({ name: "service_SwitchType" });
  return (
    <FormControl sx={{ mt: 8 }}>
      <FormLabel>
        <Typography color="textPrimary" fontWeight="bold">
          {t("labels_switchType_prompt")}
        </Typography>
      </FormLabel>
      <RadioGroup aria-labelledby="supplier-group" {...field}>
        <FormControlLabel
          value={ShellAccountEnrollmentTypeEnum.PendingSwitchRequest}
          label={
            <Typography variant="body1" lineHeight="2.5">
              {t("labels_switchTypeAnotherSupplier")}
            </Typography>
          }
          control={
            <Radio
              inputProps={
                {
                  "data-cy": "new-supplier-choice-radiobutton",
                } as InputHTMLAttributes<HTMLInputElement>
              }
              data-cy="new-supplier-choice"
            />
          }
        />
        <FormControlLabel
          value={ShellAccountEnrollmentTypeEnum.PendingMoveInRequest}
          label={
            <Typography variant="body1" lineHeight="2.5">
              {t("labels_switchTypeNewConnection")}
            </Typography>
          }
          control={
            <Radio
              inputProps={
                {
                  "data-cy": "new-connection-choice-radiobutton",
                } as InputHTMLAttributes<HTMLInputElement>
              }
              data-cy="new-connection-choice"
            />
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

type ServiceSwitchDateProps = {
  invalidDate: ReturnType<typeof useBooleanState>;
  verifyAddress: (values: GetReadyFormData) => Promise<void>;
  setVerifyType: React.Dispatch<
    React.SetStateAction<"esiId" | "address" | null>
  >;
};

const ServiceSwitchDate = ({
  verifyAddress,
  setVerifyType,
}: ServiceSwitchDateProps) => {
  const { t } = useTranslation("enrollment/formFields");
  const switchDateErrorTranslations = {
    [BlockedDate.PAST]: t("validation_switchDateInThePast"),
    [BlockedDate.SUNDAY]: t("validation_switchDateSunday"),
    [BlockedDate.FUTURE]: t("validation_switchDateFuture"),
    [BlockedDate.BLACKOUT_DAY]: t("validation_switchDateBlackout"),
    [BlockedDate.LATE_IN_DAY]: t("validation_switchDateLateInTheDay"),
  };
  const {
    clearErrors,
    getValues,
    setValue,
    setError,
    trigger,
    watch,
    formState: { errors },
  } = useGetReadyFormContext();
  const activeData = getValues();
  const incompleteAddress =
    !activeData.service_Address ||
    !activeData.service_ZipCode ||
    !activeData.service_City ||
    !activeData.service_State;

  const { data } = useQueryAvailableServiceDates({
    variables: {
      esiID: activeData.esiId,
    },
    skip: !activeData.esiId,
  });
  const availableSwitchDates = data?.availableServiceDates;

  useEffect(() => {
    if (availableSwitchDates?.length) {
      const modifiedSwitchDate = addDays(new Date(availableSwitchDates[0]), 1);
      setValue("service_SwitchDate", modifiedSwitchDate);
      clearErrors("service_SwitchDate");
      return;
    }
    clearErrors("service_SwitchDate");
  }, [JSON.stringify(availableSwitchDates), activeData.service_ZipCode]);

  useEffect(() => {
    if (!incompleteAddress || activeData.esiId) {
      clearErrors("service_SwitchDate");
    }
  }, [incompleteAddress, activeData.esiId]);

  useEffect(() => {
    trigger("service_SwitchDate");
  }, [activeData.esiId]);

  const checkForEsiId = () => {
    if (incompleteAddress && !activeData.esiId) {
      return;
    }

    if (incompleteAddress) {
      verifyAddress(activeData);
      setVerifyType("esiId");
    }

    if (!activeData.esiId) {
      verifyAddress(activeData);
      setVerifyType("address");
    }
    return;
  };

  return (
    <Box onClick={checkForEsiId}>
      <DesktopDatePicker
        label={`${t("switchDate")}${
          incompleteAddress && !activeData.esiId ? t("enterAddressFirst") : ""
        }`}
        disabled={incompleteAddress && !activeData.esiId}
        onChange={(date) => {
          if (date) {
            trigger("service_SwitchDate");
            setValue("service_SwitchDate", date);
          }
        }}
        onClose={() => {
          trigger("service_SwitchDate");
        }}
        value={watch().service_SwitchDate}
        minDate={new Date(availableSwitchDates?.[0]) || ""}
        // @ts-ignore
        PaperProps={{ borderWidth: "none" }}
        shouldDisableDate={(date) => {
          const formattedDate = format(date, "yyyy-MM-dd");
          return !availableSwitchDates?.includes(formattedDate);
        }}
        renderInput={(params: TextFieldProps) => (
          <TextField
            required
            data-cy="switch-date"
            {...params}
            sx={{ mr: 2, mt: 2, minWidth: 400 }}
            name="service_SwitchDate"
            helperText={errors.service_SwitchDate?.message || " "}
            error={Boolean(errors.service_SwitchDate)}
            onBlur={(e) => {
              if (!e.target.value) {
                return;
              }

              const date = new Date(e.target.value);
              const formattedDate = format(date, "yyyy-MM-dd");

              if (!availableSwitchDates?.includes(formattedDate)) {
                const switchDateError = getSwitchDateStatus(date);
                if (switchDateError !== null) {
                  setError("service_SwitchDate", {
                    message: switchDateErrorTranslations[switchDateError],
                  });
                }
              }
            }}
          />
        )}
      />
      <Typography variant="caption" component="p">
        <b>{t("serviceSwitchDateMoreInfoTitle")}</b>{" "}
        {t("serviceSwitchDateMoreInfoBody")}
      </Typography>
    </Box>
  );
};

const CriticalCare = () => {
  const { t } = useTranslation("enrollment/formFields");
  const { register, watch } = useGetReadyFormContext();
  return (
    <>
      <FormGroup data-cy="critical-care" sx={{ mt: 8 }}>
        <FormLabel sx={{ mb: 1 }}>
          <Typography color="textPrimary" fontWeight="bold">
            {t("labels_criticalCare_prompt")}
          </Typography>
        </FormLabel>
        <FormControlLabel
          control={
            <Checkbox
              inputProps={
                {
                  "data-cy": "critical-care-checkbox-input",
                } as InputHTMLAttributes<HTMLInputElement>
              }
              data-cy="critical-care-checkbox"
              {...register("criticalCare")}
              checked={watch().criticalCare}
            />
          }
          label={
            <Typography variant="body2" data-cy="critical-care-checkbox-label">
              {t("labels_criticalCare_check")}
            </Typography>
          }
        />
      </FormGroup>
      <Collapse in={watch().criticalCare}>
        <Typography
          color="warning"
          variant="body2"
          mt={2}
          data-cy="critical-care-warning"
        >
          {t("labels_criticalCare_error")}
        </Typography>
      </Collapse>
    </>
  );
};

const SubmitGetReadyForm = () => {
  const { t } = useTranslation("enrollment/formFields");
  const {
    watch,
    formState: { isSubmitting, isValid, errors },
  } = useGetReadyFormContext();
  const isPrepay = useSelectedEnrollmentProductIsPrepay();

  return (
    <Box mt={4} maxWidth={475}>
      <LoadingButton
        type="submit"
        data-cy="next-step"
        fullWidth
        loading={isSubmitting}
        disabled={!isValid || Boolean(errors.service_SwitchDate)}
      >
        {(() => {
          if (isPrepay && watch().criticalCare) {
            return t("switchProduct");
          }
          return t("button_Personal");
        })()}
      </LoadingButton>
    </Box>
  );
};

type GetReadyFormProps = {
  onSubmit: SubmitHandler<GetReadyFormData>;
  verifyAddress: (values: ServiceAddressPartial) => Promise<void>;
  setVerifyType: React.Dispatch<
    React.SetStateAction<"esiId" | "address" | null>
  >;
};

export const GetReadyForm: FC<GetReadyFormProps> = ({
  onSubmit,
  verifyAddress,
  setVerifyType,
}) => {
  const { t } = useTranslation("enrollment/formFields");
  const { t: tProductCard } = useTranslation("product/productCard");
  const { handleSubmit, watch } = useGetReadyFormContext();
  const productIsPrepay = useSelectedEnrollmentProductIsPrepay();
  const invalidDate = useBooleanState(false);
  const { zipCodeHasMultipleTdspsOrLoadZones, customerHasEnteredAddressEarly } =
    useEnrollment();
  const disableAddressFields =
    zipCodeHasMultipleTdspsOrLoadZones && customerHasEnteredAddressEarly;

  return (
    <>
      {zipCodeHasMultipleTdspsOrLoadZones &&
        !customerHasEnteredAddressEarly && (
          <Box display="flex" justifyContent="center" my={2}>
            <Alert severity="info" icon={<IconInfo color="currentColor" />}>
              {tProductCard("youLiveInMultipleLZAreaNoLink")}
            </Alert>
          </Box>
        )}
      <Container
        maxWidth="sm"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 8 }}
      >
        <Typography
          variant="h3"
          mb={3}
          data-cy="personal-information-section-title"
        >
          {t("personalInformationSectionTitle")}
        </Typography>
        <PersonalDetails />
        <Typography variant="h3" mt={6} mb={3} data-cy="address-section-header">
          {t("heading_GetReady")}
        </Typography>
        <Collapse in={!watch().searchByESI}>
          <SearchByAddress disabled={disableAddressFields} />
        </Collapse>
        <Collapse in={watch().searchByESI}>
          <SearchByEsiId disabled={disableAddressFields} />
        </Collapse>
        <ServiceSwitchType />
        <ServiceSwitchDate
          invalidDate={invalidDate}
          verifyAddress={verifyAddress}
          setVerifyType={setVerifyType}
        />
        {productIsPrepay ? <CriticalCare /> : <CreditCheckOrDeposit />}
        <SubmitGetReadyForm />
      </Container>
    </>
  );
};
