import { ApolloError } from "@apollo/client";
import { AutopayPreferenceChoices } from "@core/portalAccountSettings";
import {
  EditAchPaymentMethodForm,
  EditAchPaymentMethodFormValues,
} from "@octopus-energy/coral-mui";
import { StripeError } from "@stripe/stripe-js";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { ChangeEvent, FC, FormEvent } from "react";
import { useBooleanState } from "react-use-object-state";

export type EditAchPaymentMethodProps = {
  editAchPayment?: VoidFunction;
  error: ApolloError | Error | null;
  onSubmit: (
    e: FormEvent,
    bankAccountInputArgs: EditAchPaymentMethodFormValues
  ) => void;
  loading?: boolean;
  stripeError: StripeError;
  stripeErrorMessage: string;
  setStripeError?: (val: string | null) => void;
  autopayPreference?: AutopayPreferenceChoices | null;
  handleAutopayPreferenceChange: (event: ChangeEvent<HTMLInputElement>) => void;
  showAutopayErrorMessage?: ReturnType<typeof useBooleanState>;
  handleAuthorizeAutopayCheckboxChange: (
    e: ChangeEvent<HTMLInputElement>
  ) => void;
  showAuthorizeAutopayError: ReturnType<typeof useBooleanState>;
  isPrepay: boolean | undefined | null;
  preEnrolledInAutopay: Boolean;
};

export const EditAchPaymentMethod: FC<EditAchPaymentMethodProps> = ({
  editAchPayment,
  error,
  onSubmit,
  loading,
  stripeError,
  stripeErrorMessage,
  setStripeError,
  autopayPreference,
  handleAutopayPreferenceChange,
  showAutopayErrorMessage,
  handleAuthorizeAutopayCheckboxChange,
  showAuthorizeAutopayError,
  isPrepay,
  preEnrolledInAutopay,
}) => {
  const { t: tCommon } = useTranslation("common");
  const { t: tNewBankAccount } = useTranslation(
    "payments/add-new-bank-account"
  );
  const locale = useRouter().locale;

  const handleCancel = () => {
    setStripeError && setStripeError(null);
    editAchPayment && editAchPayment();
  };

  return (
    <EditAchPaymentMethodForm
      labels={{
        routingNumber: tNewBankAccount("routingNumber"),
        accountNumber: tNewBankAccount("accountNumber"),
        accountHolderName: tNewBankAccount("accountHolderName"),
        accountHolderType: tNewBankAccount("accountHolderType"),
        individualAccountHolderType: tNewBankAccount("individual"),
        companyAccountHolderType: tNewBankAccount("company"),
        submit: tCommon("submit"),
        authorization: tNewBankAccount("authorization"),
      }}
      onSubmit={onSubmit}
      loading={loading}
      errorMessage={
        stripeErrorMessage
          ? stripeErrorMessage
          : stripeError
          ? stripeError.message
          : error?.message
      }
      onCancel={handleCancel}
      locale={locale}
      autopayPreference={autopayPreference}
      handleAutopayPreferenceChange={handleAutopayPreferenceChange}
      showAutopayErrorMessage={showAutopayErrorMessage}
      handleAuthorizeAutopayCheckboxChange={
        handleAuthorizeAutopayCheckboxChange
      }
      showAuthorizeAutopayError={showAuthorizeAutopayError}
      isPrepay={Boolean(isPrepay)}
      preEnrolledInAutopay={preEnrolledInAutopay}
    />
  );
};
