import { PaymentType } from "@core/apiRequests/graphql-global-types";
import { Button, Typography } from "@krakentech/coral";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import React, { FC } from "react";

export type DeletePaymentMethodDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (option: string) => void;
  selectedOption: string;
  onOptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  paymentMethodToBeDeleted: PaymentType.Card | PaymentType.DirectDebit;
  creditCardOnFile: boolean;
  bankAccountOnFile: boolean;
  isDeletingAutopayMethod: boolean;
  isPrepay: boolean | undefined | null;
};

export const DeletePaymentMethodDialog: FC<DeletePaymentMethodDialogProps> = ({
  open,
  onClose,
  onConfirm,
  selectedOption,
  onOptionChange,
  paymentMethodToBeDeleted,
  creditCardOnFile,
  bankAccountOnFile,
  isDeletingAutopayMethod,
  isPrepay,
}) => {
  const { t } = useTranslation("payments/payment-methods");

  const getRadioOptions = () => {
    const isCard = paymentMethodToBeDeleted === PaymentType.Card;
    const isDirectDebit = paymentMethodToBeDeleted === PaymentType.DirectDebit;

    const renderOption = (value: string, label: string) => (
      <FormControlLabel
        key={value}
        value={value}
        control={<Radio />}
        label={<Typography variant="body2">{label}</Typography>}
      />
    );

    if (isDeletingAutopayMethod) {
      if (isDirectDebit && creditCardOnFile) {
        return [
          renderOption(
            "replaceAutopayBankAccount",
            t("replaceMyAutopayBankAccount")
          ),
          renderOption(
            "switchToCreditCardAutopay",
            t("switchToCreditCardAutopay")
          ),
          !isPrepay && renderOption("turnOffAutopay", t("turnOffAutopay")),
        ];
      }

      if (isDirectDebit && !creditCardOnFile) {
        return [
          renderOption(
            "replaceAutopayBankAccount",
            t("replaceMyAutopayBankAccount")
          ),
          renderOption(
            "getStartedWithCreditCardAutopay",
            t("getStartedWithCreditCardAutopay")
          ),
          !isPrepay &&
            renderOption(
              "turnOffAutopayAndAddBankAccount",
              t("turnOffAutoPayAndAddBankAccount")
            ),
          !isPrepay &&
            renderOption(
              "turnOffAutopayAndAddCreditCard",
              t("turnOffAutoPayAndAddCreditCard")
            ),
        ];
      }

      if (isCard && bankAccountOnFile) {
        return [
          renderOption(
            "switchToBankAccountAutopay",
            t("switchToBankAccountAutopay")
          ),
          renderOption(
            "replaceAutopayCreditCard",
            t("replaceMyAutopayCreditCard")
          ),
          !isPrepay && renderOption("turnOffAutopay", t("turnOffAutopay")),
        ];
      }

      if (isCard && !bankAccountOnFile) {
        return [
          renderOption(
            "getStartedWithBankAccountAutopay",
            t("getStartedWithBankAccountAutopay")
          ),
          renderOption(
            "replaceAutopayCreditCard",
            t("replaceMyAutopayCreditCard")
          ),
          !isPrepay &&
            renderOption(
              "turnOffAutopayAndAddBankAccount",
              t("turnOffAutoPayAndAddBankAccount")
            ),
          !isPrepay &&
            renderOption(
              "turnOffAutopayAndAddCreditCard",
              t("turnOffAutoPayAndAddCreditCard")
            ),
        ];
      }
    }

    if (!isDeletingAutopayMethod) {
      if (
        (isDirectDebit && !creditCardOnFile) ||
        (isCard && !bankAccountOnFile)
      ) {
        return [
          renderOption(
            "getStartedWithBankAccountAutopay",
            t("getStartedWithBankAccountAutopay")
          ),
          renderOption(
            "getStartedWithCreditCardAutopay",
            t("getStartedWithCreditCardAutopay")
          ),
          renderOption("addNewBankAccount", t("addBankAccount")),
          renderOption("addNewCreditCard", t("addCreditCard")),
        ];
      }

      if (isDirectDebit && creditCardOnFile) {
        return [
          renderOption(
            "addNewBankAccount",
            t("replaceMyBankAccountWithANewOne")
          ),
          renderOption("confirmDeletion", t("deleteAndReturnToPreviousScreen")),
        ];
      }

      if (isCard && bankAccountOnFile) {
        return [
          renderOption("addNewCreditCard", t("replaceMyCreditCardWithANewOne")),
          renderOption("confirmDeletion", t("deleteAndReturnToPreviousScreen")),
        ];
      }
    }

    return null;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h3">{t("deletingPaymentMethod")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack mb={1}>
          <Typography variant="body2">
            {t("youreDeletingYourPaymentMethod1") + " "}
            <b>{t("youreDeletingYourPaymentMethod2") + " "}</b>
            {t("youreDeletingYourPaymentMethod3")}
          </Typography>
        </Stack>
        <Stack>
          <FormControl component="fieldset">
            <RadioGroup value={selectedOption} onChange={onOptionChange}>
              {getRadioOptions()}
            </RadioGroup>
          </FormControl>
        </Stack>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            variant="contained"
            onClick={() => onConfirm(selectedOption)}
            disabled={selectedOption === ""}
          >
            {t("saveChanges")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
