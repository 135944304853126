import { obtainNewKrakenToken } from "@core/apiRequests";
import { PaymentType } from "@core/apiRequests/graphql-global-types";
import { useGetEmbeddedSecretForNewPaymentInstructionWithoutAccountMutation } from "@core/apiRequests/payment/useGetEmbeddedSecretForNewPaymentInstructionWithoutAccount";
import { useEnrollment } from "@core/enrollment/enrollmentUtils";
import { handleError } from "@core/error";
import { Button, Typography } from "@krakentech/coral";
import { Box, Stack } from "@octopus-energy/coral-mui";
import { useStripe } from "@stripe/react-stripe-js";
import { PaymentMethod } from "@stripe/stripe-js";
import useTranslation from "next-translate/useTranslation";
import { useBooleanState } from "react-use-object-state";
import { ConnectedBankAccount } from "./ConnectedBankAccount";

export type BankAccountDetailsType = Pick<
  PaymentMethod.UsBankAccount,
  "last4" | "bank_name"
> &
  Pick<PaymentMethod, "id"> & {
    secretKey: string | undefined;
  };

export const BankAccountPaymentMethod = () => {
  const { t } = useTranslation("enrollment/finalize");
  const stripe = useStripe();
  const enrollment = useEnrollment();
  const { getReady } = enrollment.formData;
  const { financialConnectionsDetails, setFinancialConnectionsDetails } =
    enrollment;
  const [getEmbeddedSecretForNewPaymentInstructionWithoutAccount] =
    useGetEmbeddedSecretForNewPaymentInstructionWithoutAccountMutation();
  const showConnectBankButton = useBooleanState(
    !Boolean(financialConnectionsDetails)
  );
  const loading = useBooleanState(false);
  const connectionsError = useBooleanState(false);

  const handleStripeFinancialConnectionsSetup = async () => {
    loading.setTrue();
    connectionsError.setFalse();
    try {
      const orgToken = await obtainNewKrakenToken().then((data) => data.token);
      if (orgToken) {
        const { data } =
          await getEmbeddedSecretForNewPaymentInstructionWithoutAccount({
            variables: {
              input: {
                instructionType: PaymentType.DirectDebit,
              },
            },
            context: {
              headers: {
                authorization: orgToken,
              },
            },
          });
        const secretKey =
          data?.getEmbeddedSecretForNewPaymentInstructionWithoutAccount
            ?.secretKey;
        if (secretKey) {
          const collectBankAccount = await stripe?.collectBankAccountForSetup({
            clientSecret: secretKey,
            params: {
              payment_method_type: "us_bank_account",
              payment_method_data: {
                billing_details: {
                  name: `${getReady?.firstName} ${getReady?.lastName}`,
                  email: getReady?.emailAddress,
                },
              },
            },
            expand: ["payment_method"],
          });
          if (collectBankAccount) {
            showConnectBankButton.setFalse();
            if (collectBankAccount.setupIntent?.payment_method) {
              const paymentMethod =
                collectBankAccount.setupIntent.payment_method;
              if (typeof paymentMethod !== "string" && paymentMethod?.id) {
                setFinancialConnectionsDetails({
                  id: paymentMethod.id,
                  last4: paymentMethod.us_bank_account?.last4 ?? "",
                  bank_name: paymentMethod.us_bank_account?.bank_name ?? "",
                  secretKey,
                });
              }
            } else {
              showConnectBankButton.setTrue();
            }
          }
        }
      }
    } catch (err) {
      handleError(err);
      connectionsError.setTrue();
    }
    loading.setFalse();
  };

  return (
    <Stack>
      <Typography variant="body2">{t("toLinkBankAccount")}</Typography>
      <Box py={2}>
        <Box p={2}>
          {showConnectBankButton.state && (
            <Button
              color="secondary"
              fullWidth
              onClick={handleStripeFinancialConnectionsSetup}
              id="link-element"
            >
              {loading.state ? "Connecting..." : t("connectWithStripe")}
            </Button>
          )}
          {!showConnectBankButton.state &&
            financialConnectionsDetails?.last4 &&
            financialConnectionsDetails.bank_name && (
              <ConnectedBankAccount
                last4={financialConnectionsDetails.last4}
                bank_name={financialConnectionsDetails.bank_name}
              />
            )}
        </Box>
        {connectionsError.state && (
          <Typography variant="caption" color="error">
            {t("bankAccountError")}
          </Typography>
        )}
      </Box>
      <Typography variant="caption">
        <b>{t("goodToKnow")}</b> {t("paymentTakeAFewDaysToProcess")}
      </Typography>
    </Stack>
  );
};
