import {
  Collapse,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { InputHTMLAttributes, useEffect } from "react";
import {
  CreditCheckType,
  useGetReadyFormContext,
  useGetReadyFormController,
} from "..";
import { SocialSecurityNumberField } from "./SocialSecurity";

export const CreditCheckOrDeposit = () => {
  const { t } = useTranslation("enrollment/formFields");
  const { watch, clearErrors } = useGetReadyFormContext();
  const { field } = useGetReadyFormController({
    name: "creditCheckType",
  });

  useEffect(() => {
    if (watch().creditCheckType === CreditCheckType.SKIP_WITH_DEPOSIT) {
      clearErrors("socialSecurityNumber");
    }
  }, [watch().creditCheckType]);

  return (
    <>
      <Typography variant="h6" mt={8} data-cy="credit-check-prompt">
        {t("labels_credit_prompt")}
      </Typography>
      <FormControl>
        <RadioGroup aria-labelledby="credit-check-group" {...field}>
          <FormControlLabel
            value={CreditCheckType.RUN_CREDIT_CHECK}
            control={
              <Radio
                inputProps={
                  {
                    "data-cy": "credit-check-choice-radiobutton",
                  } as InputHTMLAttributes<HTMLInputElement>
                }
                data-cy="credit-check-choice"
              />
            }
            label={
              <Typography variant="body1" lineHeight="2.5">
                {t("labels_creditCheck")}
              </Typography>
            }
          />
          <FormControlLabel
            value={CreditCheckType.SKIP_WITH_DEPOSIT}
            control={
              <Radio
                inputProps={
                  {
                    "data-cy": "deposit-choice-radiobutton",
                  } as InputHTMLAttributes<HTMLInputElement>
                }
                data-cy="deposit-choice"
              />
            }
            label={
              <Typography variant="body1" lineHeight="2.5">
                {t("labels_creditDeposit")}
              </Typography>
            }
          />
        </RadioGroup>
      </FormControl>
      <Collapse
        in={watch().creditCheckType === CreditCheckType.RUN_CREDIT_CHECK}
        sx={{ mt: 2 }}
      >
        <SocialSecurityNumberField />
        <Typography variant="body2" data-cy="authorize-credit-check">
          {t("labels_social_prompt")}
        </Typography>
        <Typography variant="body2" mt={2} data-cy="soft-credit-check-info">
          {t("labels_social_prompt2")}
        </Typography>
      </Collapse>
    </>
  );
};
