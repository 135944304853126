import { ApolloError } from "@apollo/client";
import { EditAchPaymentMethodFormValues } from "@octopus-energy/coral-mui";
import { useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useState } from "react";
import {
  QUERY_DEFAULT_PAYMENT_INSTRUCTION,
  useStoreAchDirectDebitInstructionMutation,
} from "../apiRequests";
import { PaymentType } from "../apiRequests/graphql-global-types";
import { handleError } from "../error";
import { useSnackbarNotification } from "../molecules";
import { useStripeError } from "../stripe/useStripeError";

/**
 * Hook that returns a function to create a stripe bank account token
 * @example
 * const createStripeBankAccountToken = useCreateStripeBankAccountToken();
 * createStripeBankAccountToken({...achPaymentMethodFormArgs});
 */
const useCreateStripeBankAccountToken = () => {
  const stripe = useStripe();
  return ({
    routingNumber,
    accountHolderName,
    accountHolderType,
    accountNumber,
  }: EditAchPaymentMethodFormValues) => {
    return stripe?.createToken("bank_account", {
      country: "US",
      currency: "usd",
      routing_number: routingNumber,
      account_number: accountNumber,
      account_holder_name: accountHolderName,
      account_holder_type: accountHolderType,
    });
  };
};

/**
 * Hook that returns a function create stripe bank account token and store
 * the token in kraken
 * @example
 * const createBankAccountPaymentMethod = useCreateBankAccountPaymentMethod();
 * createBankAccountPaymentMethod({...achPaymentMethodFormArgs});
 */
export const useCreateBankAccountPaymentMethod = () => {
  const router = useRouter();
  const accountNumber = router.query.accountNumber as string;
  const [notification] = useSnackbarNotification();
  const { t } = useTranslation("payments/payment-methods");

  const createStripeBankAccountToken = useCreateStripeBankAccountToken();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApolloError | Error | null>(null);
  const { stripeError, stripeErrorMessage, setStripeError } = useStripeError();

  const [storeAchDirectDebitInstruction] =
    useStoreAchDirectDebitInstructionMutation({
      refetchQueries: [
        {
          query: QUERY_DEFAULT_PAYMENT_INSTRUCTION,
          variables: {
            accountNumber,
            instructionType: PaymentType.DirectDebit,
          },
        },
        {
          query: QUERY_DEFAULT_PAYMENT_INSTRUCTION,
          variables: {
            accountNumber,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        notification.success(t("successVerification"));
        setStripeError(null);
        setError(null);
      },
    });

  const createBankAccountPaymentMethod = async (
    achPaymentMethodFormValues: EditAchPaymentMethodFormValues
  ) => {
    setLoading(true);
    setError(null);
    try {
      const data = await createStripeBankAccountToken(
        achPaymentMethodFormValues
      );
      if (!data?.token) {
        if (data?.error) {
          setStripeError(data.error);
          return;
        }
        return;
      }
      const response = await storeAchDirectDebitInstruction({
        variables: {
          input: {
            accountNumber,
            secretKey: data.token.id,
          },
        },
      });
      return response;
    } catch (error) {
      handleError(error);
      setError(error as ApolloError | Error);
      return;
    } finally {
      setLoading(false);
    }
  };

  return [
    createBankAccountPaymentMethod,
    {
      loading,
      error,
      stripeError,
      stripeErrorMessage,
      setStripeError,
    },
  ] as [
    typeof createBankAccountPaymentMethod,
    {
      loading: typeof loading;
      error: typeof error;
      stripeError: StripeError;
      stripeErrorMessage: string;
      setStripeError: (val: string | null) => void;
    }
  ];
};
